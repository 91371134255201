
import './index.scss'
import AnimatedLetters from '../AnimatedLetters'
import { useEffect, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngular, faGitAlt, faJsSquare, faHtml5, faReact,faCss3 } from '@fortawesome/free-brands-svg-icons'
import Loader from 'react-loaders'

const About = () =>  {

    const [letterClass, setLetterClass] = useState('text-animate')

    useEffect(()=> {
        const timeoutId = setTimeout(()=>{
            setLetterClass('text-animate-hover');
        }, 3000);

        return() => {
            clearTimeout(timeoutId);
        }
    },[])

    return (
        <>
            <div className="container about-page">
                <div className="text-zone">
                    <h1>
                        <AnimatedLetters letterClass={letterClass} strArray= {['A','b','o','u','t','  ', 'm','e']} idx={15} />
                    </h1>
                    <p>
                    I'm a data-driven professional with a blend of experience in data engineering and program management. I’ve worked on optimizing complex systems, building intuitive databases, and making data accessible and actionable for different teams. Most recently, I created a centralized AI demo database at Intel that helped sales teams quickly find the right resources, cutting down search time and improving overall efficiency. I take pride in translating technical details into practical solutions that support business goals.
                    </p>
                    <p>
                    Colleagues know me as someone who’s reliable and easy to collaborate with. I’m always ready to listen and bring a positive attitude to every project. I enjoy working across teams to solve problems and deliver results that make a tangible impact. I’m excited to explore new opportunities in data engineering and program management, where I can combine my technical expertise with a focus on project success.
                    </p>
                
                </div>
                <div className="stage-cube-cont">
                    <div className="cubespinner">
                        <div className="face1">
                            <FontAwesomeIcon icon={faAngular} color='#DD0031'/>
                        </div>
                        <div className="face2">
                            <FontAwesomeIcon icon={faHtml5} color='#f06429'/>
                        </div>
                        <div className="face3">
                            <FontAwesomeIcon icon={faCss3} color='#28a4d9'/>
                        </div>
                        <div className="face4">
                            <FontAwesomeIcon icon={faReact} color='#5ed4f4'/>
                        </div>
                        <div className="face5">
                            <FontAwesomeIcon icon={faJsSquare} color='#efd81d'/>
                        </div>
                        <div className="face6">
                            <FontAwesomeIcon icon={faGitAlt} color='#ec4d28'/>
                        </div>
                    </div>
                </div>
            </div>
            <Loader type='pacman'/>
        </>
    )
}

export default About