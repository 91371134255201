import Loader from 'react-loaders'
import './index.scss'
import AnimatedLetters from '../AnimatedLetters'
import { useState, useEffect, useRef } from 'react'
import emailjs from '@emailjs/browser'
import {MapContainer, TileLayer, Marker, Popup} from 'react-leaflet'


const Contact = () => {
  const [letterClass, setLetterClass] = useState('text-animate')
  const form = useRef()

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setLetterClass('text-animate-hover')
    }, 3000)
    return () => {
      clearTimeout(timeoutId)
    }
  }, [])

  const sendEmail = (e) => {
    e.preventDefault()

    emailjs
      .sendForm('service_hby2bwk', 'template_yro5hjh', form.current, {
        publicKey: 'Zqd77E7rlBv0ZSIX2',
      })
      .then(
        () => {
          alert('Message successfully sent!')
          window.location.reload(false)
        },
        () => {
          alert('Failed to send the message, please try again')
        }
      )
  }


  return (
    <>
      <div className="container contact-page">
        <div className="text-zone">
          <h1>
            <AnimatedLetters
              letterClass={letterClass}
              strArray={['C', 'o', 'n', 't', 'a', 'c', 't', '  ', 'm', 'e']}
              idx={15}
            />
          </h1>
          <p>
          Thank you for visiting my portfolio! If you’d like to connect, discuss a project, or just say hello, feel free to reach out. I’m always open to exploring new opportunities and collaborations. You can contact me via the form below or connect with me on LinkedIn. I look forward to hearing from you!{' '}
          </p>

          <div className="contact-form">
            <form ref={form} onSubmit={sendEmail}>
              <ul>
                <li className="half">
                  <input type="text" name="name" placeholder="Name" required />
                </li>
                <li className="half">
                  <input
                    type="email"
                    name="email"
                    placeholder="Email"
                    required
                  />
                </li>
                <li>
                  <input
                    type="text"
                    name="subject"
                    placeholder="Subject"
                    required
                  />
                </li>
                <li>
                  <textarea
                    name="message"
                    placeholder="Message"
                    required
                  ></textarea>
                </li>
                <li>
                  <input type="submit" className="flat-button" value={'SEND'} />
                </li>
              </ul>
            </form>
          </div>
        </div>
        <div className="info-map">
          Max Mena,
          <br />
          Costa Rica.
          <br />
          <span>Gmail: raemxam</span>
          
        </div>
        <div className="map-wrap">
          <MapContainer center={[10.0162500,-84.2116300]} zoom={13}>
            <TileLayer url='https://tile.openstreetmap.org/{z}/{x}/{y}.png'/>
            <Marker position={[10.0162500, -84.2116300]}>
              <Popup>The city of the mango, where I'm from!</Popup>
            </Marker>
          </MapContainer>
        </div>
      </div>
      <Loader type="pacman" />
    </>
  )
}

export default Contact
